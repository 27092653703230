@use '../../App';
.hamburger{
  position: fixed;
  height: App.$hamburger-height;
  width: App.$hamburger-height;
  border-radius: 50%;
  
  //top: $hamburger-padding;
  //right: $hamburger-padding;
  top: 20px;
  right: 20px;
  //needs index of 2 because it comes before hero comp
  z-index: 3;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //display: none;
  border: solid grey;
  background: white;
  overflow: hidden;
  .hamburgerIconWrapper{
    position: relative;
    height: 100%;
    width: 100%;
    .hamburgerIcon{
      width: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      img{
        width: 100%;
      }
    }
    .closeHamburgerIcon{
      width: 28px;
      height: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      img{
        width: 100%;
      }
    }
  }
}
.hamburgerMenu{
  width: 100%;
  //display: none;
  //pointer-events: none;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  //background: pink;
  z-index: 2;
  //hidden to let stunt double do its thing
  overflow: hidden;
  .hamburgerWrap{
    position: relative;
    width: 100%;
    height: 100%;
    //background: green;
    .hamburgerTopPanel{
      width: 100%;
      height: 10px;
      background: white;
      position: absolute;
      top:0;
    }
    .hamburgerBottomPanel{
      width: 100%;
      height: 10px;
      
      position: absolute;
      bottom:0;
      pointer-events: none;
      .hamburgerBottomPanelInner{
        background: white;
        height: 100%;
        width: 100%;
      }
    }
    .hamburgerRightPanel{
      width: 10px;
      height: 100%;
      background: white;
      position: absolute;
      right: 0;
      
    }
    .hamburgerWhitePanel{
      position: absolute;
      width: calc(100% - 80px);
      height: 100%;
      background: white;
      top: 0;
      left: 0;
      //padding: 10px 0 10px 10px;
      #contentWrap{
        width: 100%;
        height: 100%;
        position: relative;
        //background: pink;
        //padding: 10px;
        //margin-top: 10px 0 10px 0;
        //padding: 10px 0;
        
        .innerContent{
          width: 100%;
          height: calc(100% - 20px);
          margin: 10px 0 10px 0;
          //background: purple;
          position: relative;
          .innerPadding{
            position: relative;
            width: 100%;
            height: 100%;
            //border: solid red;
            
            .topSection{
              width: 100%;
              //border: solid red;
              //height: 100px;
              height: 34vw;
              position: absolute;
              top: 0;
              //padding: 10% 0;
              margin-top: 4vw;
              .topText{
                text-align: center;
                font-size: 15px;
              }
              .first{
                margin-top: 2vw;
              }
              @media only screen and (max-width: 400px) {
                .topText{
                  font-size: 3.5vw;
                }
              }
              @media only screen and (min-width: 470px) {
                .topText{
                  font-size: 18px;
                }
              }
              .logoWrapper{
                height: 100%;
                position: relative;
                //text-align: center;
                img{
                  height: 100%;
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
            .bottomText{
              position: absolute;
              bottom: 10%;
              padding-left: 10%;
              .socialMedia{
                margin-top: 15px;
              }
              .comingSoon{
                font-size: 12px;
                color: grey;
              }
              .bottomLinkWrap{
                color: black;
                text-decoration: none;
                .bottomTextPhone{
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-family: 'OpenSans-Regular';
                  
                  img{
                    height: 16px;
                  }
                  
                  div{
                    display: inline-block;
                    padding-left: 5px;
                    text-decoration: none;
                  color: black;
                  }
                }
              }
              .bottomEmailWrap{
                color: black;
                text-decoration: none;
                font-family: 'OpenSans-Regular';
                .bottomText1stLine{
                  display: flex;
                  align-items: center;
                  div{
                    display: inline-block;
                    padding-left: 5px;
                    font-size: 16px;
                  }
                  @media only screen and (max-width: 360px) {
                    div{
                      
                      font-size: 12px;
                    }
                  }
                }
              }
              .icon{
                height: 18px;
                
              }
              .socialMediaBar{
                height: 30px;
                img{
                  display: inline-block;
                  height: 100%;
                  
                }
                .twitter{
                  margin-left: 15px;
                }
              }
            }//end of bottomText
          }
        }
        
        
      }
    }
    .hamburgerBluePanel{
      background: App.$main-blue-color;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}
