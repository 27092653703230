.eyeWrap{
  width: 100%;
  height: 100%;
  position: relative;
  //opacity: 0;
  
  .eyeWrinklesWrap{
    width: 185%;
    height: 185%;
    //background: purple;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-55%, -60%);
    opacity: .8;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.middleEyePart{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //border: solid black;
  width: 65%;
  height: 65%;
  .middleEyeWrap{
    width: 100%;
    height: 100%;
    position: relative;
    #bluePart{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      enable-background: new 0 0 37.3 37.3;
      .st0{fill:#6BC5F7;}
    }
    #blackPart{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      enable-background:new 0 0 37.3 37.3;
      .st0{fill:#070707;}
    }
    #smallBlackPart{
      width: 85%;
      height: 85%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      enable-background:new 0 0 37.3 37.3;
      .st0{fill:#070707;}
      //opacity: 0;
    }
    #reflectionPart{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      enable-background:new 0 0 37.3 37.3;
      .st0{fill:#FFFFFF;}
    }
  }
  
}

#whitePart{
  height: 100%;
  width: 100%;
  enable-background:new 0 0 61.8 60;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .st0{ fill:#CCCCCC; }


}

#whaleEyeLid{
  height: 100%;
  width: 100%;
  enable-background: new 0 0 61.8 60;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
	.st0{fill:#303030;
    stroke:#AAAAAA;
    stroke-width:0.2616;
    stroke-miterlimit:10;
  }

}

.testDing{
  height: 100%;
  width: 100%;
  background: black;
  position: relative;

}

