@use '../../App';
.sectionDosMain{
  width: 100%;
  //height: 150vh;
  background: App.$main-color;
  //z-index: 2;
  //margin-top: calc(100vh - #{$desktop-padding})
  //padding: 0 4vw;
  padding-top: 35vh;
  .sectionDosFirstRow{

    display: grid;
    padding: 0 10vw;
    padding-bottom: 10vw;
    grid-template-columns: 40vw auto;
    //height: 40vw;
    .left{
      height: 40vw;
      width: 40vw;
      position: relative;
      overflow: hidden;
      .artBackground{
        position: absolute;
        height: 100%;
        width: 100%;
        //left: 50%;
        //transform: translateX(-50%);
      }
      .fallingGuy{
        width: 60%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .right{
      position: relative;
      .rightWrap{
        width: 100%;
        position: absolute;
        bottom: 0;
        
        font-family: 'OpenSans-Regular';
        .skills{
          margin-top: 20px;
        }
        p{
          font-size: 1.5vw;
        }
        h1{
          font-family: 'Open Sans Condensed';
          margin-bottom: 10px;
          font-size: 2.1vw;
        }
        ul{
          list-style: none;
          font-size: 1.5vw;
        }
        .rightButton{
          
          padding: 5px;
          margin: 0 auto;
          margin-top: 15px;
          //z-index: 5;
          display: inline-block;
          font-size: 18px;
          cursor: pointer;
          background: #315589;
          border: solid black;
          color: white;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          font-family: 'Open Sans Condensed';
        }
      }
    }

  }
}