.smallerWhale{
  width: 45%;
  position: absolute;
  top: 15%;
  //bottom: 0;
  left: 20%;
  //left:70;
  //transform: translateX(50%);
  //border: solid black;
  .smallerWhaleInnerWrap{
    position: relative;
    width: 100%;
    height: 100%;
    .smallerWhaleContentWrap{
      width: 18vw;
      height: 7vw;
      //border: solid purple;
      position: relative;
    }
    .smallerWhaleEye{
      //background: purple;
      width: .3vw;
      height: .3vw;
      position: absolute;
      top:58.2%;
      left:7.1%;
      transform: translate(-50%,-50%);
      //opacity: .3;
    }
    .smallerTestDot{
      position: absolute;
      width: 2px;
      height: 2px;
      top:58.2%;
      left:7.1%;
      transform: translate(-50%,-50%);
      background: red;
      display: none;
    }
    .smallerWhaleSVG{
      width: 100%;
    }
    img{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}