#artSquare{
  enable-background:new 0 0 1164 1164;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 5px);
  
  height: calc(100% + 5px);
  .st0{fill:#eeeeee;stroke:#eeeeee;stroke-width:5;stroke-miterlimit:10;}
	.st1{fill:#315589;fill-opacity:0.2;stroke:#eeeeee;stroke-width:5;stroke-miterlimit:10;}
	.st2{fill:#61A6F7;fill-opacity:0.2;stroke:#eeeeee;stroke-width:5;stroke-miterlimit:10;}
	.st3{fill:#61A6F7;stroke:#eeeeee;stroke-width:5;stroke-miterlimit:10;}
	.st4{fill:#eeeeee;}
	.st5{fill:none;stroke:#eeeeee;stroke-width:5;stroke-miterlimit:10;}
}