
.largeWhaleHorizontal{
  
  //border: solid red;
  width: 40vw;
  height: 40vw;
  position: absolute;
  top: 50%;
  left: 70%;
  
  //transform: translate(-43%, -65%);

    /*
    width: 100%;
    height: 100%;
    position: absolute;
    */
  .largeWhaleVertical{
    width: 100%;
    height: 100%;
    position: absolute;
    
    .outerEyeContainer{
      width: 100%;
      height: 100%;
      //border: purple solid;
      position: absolute;
      //background: peachpuff;
      //opacity: 30%;
    }
    .innerWhaleTest{
      width: 100%;
      height: 100%;
      position: relative;
      .whaleSRC{
        position: absolute;
        top: -471%;
        left: 4630%;
        transform: translate(-50%, -50%);
        //border: pink solid;
        height: 11000%;
        width: 11000%;
      }
    }
  }
}

/*
.testMovement{
  border: solid red;
  width: 40vw;
  height: 40vw;
  position: absolute;
  top: 50%;
  left: 70%;
}
.testMovement2{
  height: 100%;
  width: 100%;
  position: absolute;
}
*/





/*
.hale{
  border: solid red;
  width: 40vw;
  height: 40vw;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  .testDOT4{
    width: 2px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: red;
  }
  .outerEyeContainer{
    width: 100%;
    height: 100%;
    //border: purple solid;
    position: absolute;
    //background: peachpuff;
    //opacity: 30%;
  }
  .innerWhaleTest{
    width: 100%;
    height: 100%;
    position: relative;
    .whaleSRC{
      position: absolute;
      top: -471%;
      left: 4617%;
      transform: translate(-50%, -50%);
      border: pink solid;
      height: 11000%;
      width: 11000%;
    }
  }
}

*/

#circleSVG{
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  -o-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}