.ocean{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(130, 130, 243);
  //overflow: hidden;
  pointer-events: none;
  #whaleEye{
    height: 100%;
    width: 100%;
    enable-background: new 0 0 236 229.3;
    position: relative;
    
    .st0{
      fill: none;
      stroke:#000000;
      stroke-width:3;
      stroke-miterlimit: 10;
    }
    .st1{
      stroke:#000000;
      stroke-miterlimit:10;
    }
    .st2{
      fill:#FFFFFF;
      stroke:#000000;
      stroke-miterlimit:10;
    }
    .st3{
      fill:#3A3A3A;
      stroke:#000000;
      stroke-miterlimit:10;
    }
  }
  .oceanPlainLarge{
    position: absolute;
    //width: 100%;
    height: 100%;
    //top: 0;
    //left: 0;
  }
  #screenWrap{
    position: relative;
    //background: black;
    width: 100%;
    height: 100vh;
    .initialOceanCover{
      position: absolute;
      height: 100%;
      //opacity: 0;
      //temp
      pointer-events: none;
    }
    
    
    .oceanBackground{
      position: absolute;
      //width: 100%;
      height: 100%;
      //temp
      pointer-events: none;
    }
    
    .sunRay{
      width:100%;
      //height: 100vh;
      position: absolute;
      top: -25px;
      left: 0;
      opacity: 80%;
    }

  }
}

