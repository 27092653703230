//import main color
@use '../../App';

.incompatibleScreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: App.$main-color;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  .incompatibleWrap{
    font-family: 'Open Sans Condensed';

    .incompatibleLogo{
      height: 26vh;
      margin: 15px 0;
      //text-align: center;

      position: relative;
      img{
        height: 100%;
        position: absolute;
        left:50%;
        transform: translateX(-50%);
      }
    }
    .incompatibleText1{
      font-size: 28px;
      text-align: center;
    }
    .incompatibleText2{
      font-size: 20px;
      text-align: center;
    }
  }
}