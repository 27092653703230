@use '../../App';

.heroMainBody{
  background: App.$whitish-color;
  //width needs vw so that scroll lock wont glitch
  width: 100%;
  
  //height: 280vh;
  //temporary position set
  position: relative;
  //hidden so if background is on first, width stays 100%
  overflow: hidden;
  .hamburgerBar{
    z-index: 1;
    width: 100%;
    background: purple;
    display: flex;
    justify-content: space-between;
  }
  .heroLogo{
    position: absolute;
    top: App.$hamburger-padding;
    left: App.$hamburger-padding;
    //top: 5vw;
    //left: 5vw;
    //padding: 10px;
    z-index: 1;
    width: calc(40% - #{App.$hamburger-padding} - #{App.$hamburger-padding});
    height: App.$hamburger-height;
    //background: black;
    display: flex;
    align-items: center;
    .logoIcon{
      width: 100%;
      max-height: App.$hamburger-height;
    }
  }
  
  .heroFrontContent{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 150px;
    width: 350px;
    border: solid black;
    z-index: 1;
  }
}