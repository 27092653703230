@use '../../App';

.background{
  position: absolute;
  background: App.$main-color;
  //transition: cubic-bezier(.13,.89,.81,.97);
  height: 100%;
  width: 100%;
  pointer-events: none;
  //opacity: .2;
}
.backgroundTempBackground{
  width: 100%;
  height: 100vh;
  background: yellow;
  position: fixed;
  background: App.$main-color;
  z-index: 3;
  pointer-events: none;
  //top: 0;
  //left:0;
}
.backgroundMainScreen{
  width: 100%;
  height: 100vh;
  //border: solid black;
  position: absolute;
  top: 0;
  left:0;
  //temp
  //pointer-events: none;
  .backgroundLogo{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 60vw;
    img{
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
  .contentWrap{
    height: 100%;
    width: 40%;
    position: relative;
    //padding-top: 5vh;
    .topLogo{
      //position: relative;
      //margin-top: 5vh;
      width: 90%;
      height: App.$hamburger-height;
      margin: auto;
      display: flex;
      align-items: center;
      .logoSRC{
        width: 100%;
        max-height: App.$hamburger-height;
      }
    }
    .heroSloganBody{
      //position: absolute;
      //left: 3%;
      //margin: 0 auto;
      //left: 2%;
      //width: 36%;
      height: 100%;
      width: 90%;
      margin-left: 5%;
      //margin: 0 100%;
      top: 2vh;
      //transform: translateY(-50%);
      .heroSloganInnerContent{
        
        //transform: translateX(50%);
        //display: inline-block;
        width: 100%;
        margin-top: 22vh;

        .heroSloganInnerContent2{
          width: 100%;
          height: 100%;
          div{
            text-align: center;
            //font-family: 'Open Sans Condensed';
            font-family: 'Poppins';
            width: 100%;
            font-size: 7vw;
            margin-bottom: 1.5vw;
          }
          .killerWord{
            color: #315589;
            //font-size: 10vw;
          }
          .heroButton{
            padding: 5px;
            font-size: 5vw;
            background: #315589;
            //position: absolute;
            //left: 50%;
            //transform: translateX(-50%);
            //bottom: 7%;
            border: solid black;
            color: white;
            text-align: center;
            //margin-left: 2vw;
            margin-top: 30px;
            cursor: pointer;
            //z-index: 99;
          }
        }
      }
    }
  } //end .content-wrap
}
