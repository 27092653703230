@use '../../App';

.sectionTwoMain{
  
  width: 100%;
  margin-top: 119vh;
  
  padding: 20px 6vw 20vh 6vw;
  position: relative;
  background: App.$main-color;
  font-family: 'OpenSans-Regular';
  font-size: 18px;
  h2{
    font-size: 28px;
    font-family: 'Open Sans Condensed';
    margin-bottom: 15px;
  }
  .ctaBottomButton{
    padding: 5px;
    background: #315589;
    border: solid black;
    color: white;
    margin: auto;
    margin-top: 25px;
    display: inline-block;
    font-family: 'Open Sans Condensed';
  }
  .services{
    list-style-type: none;
    padding-top: 25px;
  }
  .picture{
    width: 80vw;
    height: 80vw;
    //border: solid red;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .artBackground{
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .fallingGuy{
      width: 60%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    
  }
}