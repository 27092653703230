@use '../../App';
.desktopHeroMain{
  width: 100%;
  position: relative;
  //height: 100vh;
  .dummySpace{
    height: calc(100vh - #{App.$desktop-padding});
    width: 100%;
    position: relative;
  }
}