.whale{
  width: 45%;
  position: absolute;
  top: 18%;
  left: 48%;
  //height: auto;
  //transform: translate(-87%, -35%);
  
  .whaleVerticalWrap{
    width: 100%;
    height: 100%;
    position: relative;
    .whaleFin{
      //opacity: 0;
    }
    .smallEye{
      width: .7vw;
      height: .7vw;
      //background: purple;
      position: absolute;
      top:58.8%;
      left: 7.7%;
      //opacity: .3;
      
    }
    
    //dot wrap is kept in because its relative position
    .dotWrap{
      width: 45vw;
      height: 18.77vw;
      //border: solid purple;
      position: relative;
      opacity: 0;
      .dot{
        position: absolute;
        height: 2px;
        width: 2px;
        
        top: 85%;
        left: 92%;
        
        //top: 56%;
        //left: 26%;
        
        background: red;
      }
    }
    
    
    img{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    
    .whaleBody{
      //opacity: .3;
    }
    
  }
  
}