@use '../../App';
.hamburgerDesktop{
  height: 5vw;
  width: 5vw;
  //border: grey solid;
  
  //border-radius: 50%;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translate(35%, -50%);
  cursor: pointer;
  //overflow: hidden;
  padding-left: 1vw;
  z-index: 1;
  .hamburgerTextWrap{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    div{
      position: absolute;
      transform: rotate(-90deg);
      font-size: .7vw;
    }
  }
  img{
    width: 100%;
    position: absolute;
  }
  #hamburgerText{
    enable-background:new 0 0 91.6 91.6;
    position: absolute;
    //opacity: 0;
    
    .st0{fill:#0F0F0F;}
    .st1{fill:none;}
  }
}
.topBox{
  position: fixed;
  top: -1 * App.$desktop-padding;
  height: App.$desktop-padding * 2;
  width: 100%;
  background: white;
  z-index: 1;
  .desktopLogoWrap{
    position: relative;
    height: 100%;
    width: 100%;
    
    margin-left: calc(#{App.$desktop-padding} + 5px);
    .logoShell{
      height: 50%;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      //cursor: pointer;
      img{
        height: 50%;
        //margin: auto 0;
        //margin: auto 0;
      }
    }
  }
}
.bottomBox{
  position: fixed;
  bottom:-1 * App.$desktop-padding;
  height: App.$desktop-padding * 2;
  width: 100%;
  background: white;
  //opacity: .3;
  z-index: 1;
}
.leftBox{
  position: fixed;
  left: -100vw + App.$desktop-padding * 3;
  height: 100%;
  //width: $desktop-padding * 2;
  width: calc(100vw - #{App.$desktop-padding} * 2);
  background: white;
  z-index: 1;
  .panelWrap{
    width: 100%;
    height: 100%;
    //border: solid pink;
    position: relative;
    .bluePanel{
      position: absolute;
      height: 100%;
      width: 8vw;
      right: 0;
      background: App.$main-blue-color;
      opacity: 0;
      cursor: pointer;
    }
    .panelContent{
      position: absolute;
      height: 100%;
      width: calc(100% - 16vw);
      left: 8vw;
      //background: pink;
      padding-top:8vw;
      div{
        //height: calc(100vh - 8vw);
      }
      .topSection{
        width: 100%;
        //background: red;
        height: 40vh;
        top: 0;
        text-align: center;
        .topSectionText1{
          font-size: 30px;
        }
        .topSectionText2{
          font-size: 20px;
        }
        .topLogoWrap{
          height: 70%;
          position: relative;
          img{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
          }
        }
      }
      .bottomSection{
        width: 100%;
        //background: palevioletred;
        //height: 100px;
        position: absolute;
        bottom: 8vw;
        display: grid;
        grid-template-columns: 30vw auto 20vw;
        .bottomRightSection{
          //position: relative;
          padding-left: 2vw;
          .bottomLinkPhone{
            color: black;
              text-decoration: none;
              cursor: default;
              .bottomTextPhone{
                display: flex;
                align-items: center;
                font-size: 18px;
                font-family: 'OpenSans-Regular';
                
                img{
                  height: 19px;
                  cursor: pointer;
                }
                
                div{
                  display: inline-block;
                  padding-left: 5px;
                  text-decoration: none;
                  color: black;
                  cursor: pointer;
                }
              }
          }
          .bottomEmailWrap{
            color: black;
            text-decoration: none;
            font-family: 'OpenSans-Regular';
            cursor: default;
            .bottomText1stLine{
              display: flex;
              align-items: center;
              img{
                height: 20px;
                cursor: pointer;
              }
              div{
                display: inline-block;
                padding-left: 5px;
                font-size: 18px;
                cursor: pointer;
              }
            }
          }
        }
        .bottomLeftSection{
          h2{
            font-size: 14px;
            color: grey;
          }
          img{
            width: 20px;
            height: 20px;
          }
          .twitter{
            margin-left: 10px;
          }
        }
      }
    }
  }
  
}
.rightBox{
  position: fixed;
  right: -1 * App.$desktop-padding;
  height: 100%;
  width: App.$desktop-padding * 2;
  background: white;
  z-index: 1;
}
