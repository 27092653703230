@use '../../App';
.oceanDesktopMain{
  width: calc(100% - #{App.$desktop-padding});
  height: calc(100% - #{App.$desktop-padding} * 2);
  position: relative;
  margin: App.$desktop-padding 0 0 0;
  
  .sunRayDesktop{
    width:100%;
    
    position: absolute;
    top: -25px;
    left: 0;
    opacity: 80%;
  }
}

#theOcean{
  enable-background:new 0 0 1872 1872;
  height: 100%;
  width: 100%;
  position: absolute;
  .st0{fill:url(#SVGID_1_);}
}