$mobile-breakpoint: 768px;

$whitish-color: rgb(250, 250, 250);
//$main-color: rgb(247, 75, 75);
//$main-color: rgb(36, 117, 58);
//$main-color: rgb(49, 85, 137);
$main-color: rgb(238, 238, 238);
$main-blue-color: #315589;
//$hero-length: 250vh;
$hamburger-height: 50px;
$hamburger-padding: 10px;

//$desktop-padding: 80px;
$desktop-padding: 4vw;

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

//2nd update: you actually need html stuff and body height 100 for scrollTo to work
//3rd update: body height allows you to activate body scroll, but not do scroll to; html height allows for scrollTo to work

html{ height: 100%; }
body {
  //font-family: Arimo, 'sans-serif';
  //font-family: 'Open Sans Condensed', 'sans-serif';
  //font-family: 'Poppins-Bold.ttf'
  @font-face {
    font-family: "Poppins";
    src:
      local("Poppins"),
      url('Poppins-Bold.ttf');
  }
  height: 100%;
  font-size: 14px;
}
.wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 20px;
  border-radius: 2px;
  margin-top: 20px;
  background: rgba(128, 128, 128, .1);
}