@use '../../App';
.sliderMain{
  width: 100%;
  height: 100vh;
  position: absolute;
  
  //background: $main-color;
  //border: solid rgb(90, 90, 90);
  .sliderContent{
    width: 100%;
    height: 100%;
    position: relative;
    //background: $main-color;
    //Hide Blue Background, which overlaps
    overflow: hidden;
    .initialGrey{
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: App.$main-color;
      pointer-events: none;
    }
    .oceanWrap{
      position: absolute;
      right:0;
      height: 100%;
      width: 40%;
      //background: red;
    }
    
    .blueBackground{
      width: 100%;
      position: absolute;
      //opacity: 1;
      
    }
    .leftSide{
      width: 100%;
      height: 100%;
      //display: inline-block;
      //background: plum;
      position: absolute;
      //left:0;
      background: App.$main-color;
      
      .leftSideAdjuster{
        position: relative;
        width: 100%;
        height: 100%;
        //background: purple;
        .leftSideInnerWrap{
          width: 100%;
          height: 100%;
          //background: yellow;
          .logoText{
            width: calc(60% - 4vw);
            //max-width: 835px;
            //height: 100px;
            //border: solid wheat;
            position: absolute;
            top: 65%;
            left: calc(20% + 4vw);
            //left: calc(60% - 4vw);
            transform: translate(0, -50%);
            .logoTextWrapper{
              overflow: hidden;
              div{
                font-size: 5vw;
                
                font-family: "Poppins";
                
                text-align: center;
                span{
                  color: #315589;
                }
              }
            }
            
          }
        }
        .logoWrap{
          height: 27vw;
          width: 27vw;
          max-width: 400px;
          max-height: 400px;
          //border: solid black;
          position: absolute;
          top:50%;
          left: calc(50% + 2vw);
          transform: translate(-50%, -50%);


          .logoInnerContent{
            width: 100%;
            height: 100%;
            position: relative;
            //border: solid brown;
            img{
              width: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
    
        }
      }
    }
  }
}